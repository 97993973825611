.hold {
    margin-top: 6.4rem;
    text-align: center;
}

.profile {
    font-size: 1.3rem;
    font-weight: 600;
}

.isoHead {
    font-size: 1.3rem;
    font-weight: 600;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
        margin-top: 6.4rem;
        text-align: center;
    }

    .profile {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .isoHead {
        font-size: 1.3rem;
        font-weight: 600;
    }
}