.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

@media screen and (max-width:768px) {
    .hold {
        width: 100%;
    }

    .head {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .cardHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }


}