.hold {
    margin-top: 4rem;
    background-color: rgb(242, 242, 242);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.hold2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8rem;
    margin-bottom: 2rem;
}

.head {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
}

.pre {
    margin-bottom: -2rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: gray;
}

.pre1 {
    font-size: 2.5rem;
    margin-bottom: 4rem;
}

.pre2 {
    margin-top: -3.8rem;
    font-size: 2.5rem;
}

.pre2Color {
    color: rgb(223, 0, 0);
}

.right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.left {
    margin-left: 1rem;
    width: 48%;
}

.hold3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .hold2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.7rem;
        margin-bottom: 2rem;
        width: 100%;
    }

    .head {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: .9rem;
    }

    .pre {
        margin-bottom: -1.2rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .pre1 {
        font-size: 1.1rem;
        margin-bottom: 4rem;
    }

    .pre2 {
        margin-top: -3.8rem;
        font-size: 1.1rem;
    }


    .hold3 {
        width: 100%;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        width: 60%;

    }

    .left {
        margin-left: 1rem;
        width: 28%;

    }

}