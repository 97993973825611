.card {
    width: 100%;
    height: 36rem;
    padding-top: 2rem;
    background-image: linear-gradient(rgba(11, 11, 11, 0.509),
            rgb(8, 8, 8)),
        url("../asset/cleeaning.jpg");

    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.head {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
}

.head1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;

}

.head2 {
    margin-top: -1.5rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;

}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .card {
        width: 100%;
        height: 34rem;
        padding-top: 2rem;
        background-image: linear-gradient(rgba(11, 11, 11, 0.509),
                rgb(8, 8, 8)),
            url("../asset/cleeaning.jpg");

        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;

        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .head1 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
        font-weight: 600;

    }

    .head2 {
        margin-top: -1.5rem;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
        font-weight: 600;

    }

}