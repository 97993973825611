.namehold {
    margin-top: 6.4rem;
    background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-bottom: 3rem;
}

.name {
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;

}

.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.cardhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.card {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/mgm1.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card2 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/tech.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card3 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/asset.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card4 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/vendor.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}


.card5 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/mgm5.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card6 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/mechanical.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card7 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/adminstrative.webp");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.inCard {
    width: 100%;
    display: inline-block;
    align-self: flex-end;
    text-align: center;
}


.txt {
    color: white;
    /*position: absolute;
    bottom: 0;*/
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .namehold {
        margin-top: 6.4rem;
        background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        margin-bottom: 3rem;
        width: 100%;
    }

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: white;

    }

    .head {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .cardhold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .card {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card2 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card3 {


        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card4 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }


    .card5 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card6 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card7 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .inCard {
        width: 100%;
        display: inline-block;
        align-self: flex-end;
        text-align: center;
    }


    .txt {
        color: white;
        /*position: absolute;
        bottom: 0;*/
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
        font-weight: bold;
    }
}