.hold {

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0rem;
}

.namehold {
    margin-top: 6.4rem;
    background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.name {
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;

}

.right {

    background-color: rgb(229, 229, 229);
}

.left {
    padding: 1.5rem;
    background-color: rgb(246, 246, 246);

}

.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: rgb(54, 54, 54);
}

.abt1 {
    width: 34rem;
    height: 32rem;
    border-radius: 50%;
    object-fit: cover;
}

.head2 {
    text-align: center;
    margin-right: 5rem;
    margin-left: 5rem;
    color: rgb(65, 65, 65);
    font-family: 'Poppins', sans-serif;
}

.card1 {
    padding: 0.5rem;
    background-color: white;
    border-radius: 0.5rem;

}

.card2 {
    padding: 0.5rem;

    border: 1px solid gray;
    background-color: black;
    color: white;
    border-radius: 0.5rem;
}

.cardhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
}

.founder {
    border-radius: 50%;
    width: 7rem;
    margin: auto;
    display: block;
}

.name1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
}

.name2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.name3 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.name4 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

@media screen and (max-width:768px) {
    .container {
        width: 100%;
    }

    .hold {

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0rem;
        width: 100%;
    }

    .namehold {
        width: 100%;
        margin-top: 6.4rem;
        background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .name {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: white;

    }

    .right {

        background-color: rgb(229, 229, 229);
    }

    .left {
        padding: 1.5rem;
        background-color: rgb(246, 246, 246);

    }

    .head {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: rgb(54, 54, 54);
    }

    .abt1 {
        width: 80vw;
        height: 40vh;

        object-fit: cover;
        margin: auto;
        display: block;
    }

    .head2 {
        text-align: center;
        margin-right: 5rem;
        margin-left: 5rem;
        color: rgb(65, 65, 65);
        font-family: 'Poppins', sans-serif;
    }

    .card1 {
        padding: 0.5rem;
        background-color: white;
        border-radius: 0.5rem;

    }

    .card2 {
        padding: 0.5rem;

        border: 1px solid gray;
        background-color: black;
        color: white;
        border-radius: 0.5rem;
    }

    .cardhold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: .5rem;
    }

    .founder {
        border-radius: 50%;
        width: 7rem;
        margin: auto;
        display: block;
    }

    .name1 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 0.9rem;
    }

    .name2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    .name3 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }

    .name4 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}