.hold2 {
    margin-top: 6.4rem;
    background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

}

.left {
    background-color: rgb(255, 255, 255);
    margin-top: 2rem;
    border-radius: 2rem;
    border: 2px solid red;
    width: 80%;
}

.right {
    background-color: rgb(5, 5, 5);
    margin-top: 2rem;
    border-radius: 2rem;
    color: white;
}

.head {
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;
}

.hold3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.reach {
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    text-align: center;
}

.reach2 {
    font-family: 'Poppins', sans-serif;

    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.care {
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    text-align: center;

}

.care2 {
    font-family: 'Poppins', sans-serif;

    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.in {
    width: 18rem;
    height: 2rem;
    margin-bottom: 2rem;
    display: block;
    margin: auto;
}

.in2 {
    width: 18rem;
    height: 7rem;
    margin-bottom: 2rem;
    display: block;
    margin: auto;
}

.btn {
    margin: auto;
    display: block;

    border: none;
    width: 11rem;
    height: 2.5rem;
    background-color: rgb(216, 0, 0);
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 0.3rem;
}

.face {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    margin: auto;
    display: block;
}

.name {
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.icon {
    width: 3rem;


}

.ways {
    text-align: center;
    font-size: 1.7rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.otherHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.mapHold {
    margin: 5rem 0;
}

.locate {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 600;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .hold2 {
        width: 100%;
        margin-top: 6.4rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

    }

    .left {
        background-color: rgb(255, 255, 255);
        margin-top: 2rem;
        border-radius: 2rem;
        border: 2px solid red;
        width: 95%;
    }

    .right {
        background-color: rgb(5, 5, 5);
        margin-top: 2rem;
        border-radius: 2rem;
        color: white;
        width: 95%;
    }

    .head {
        text-align: center;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 3rem;
        font-weight: 600;
    }

    .hold3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .reach {
        font-family: 'Poppins', sans-serif;
        font-size: 2.2rem;
        text-align: center;
    }

    .reach2 {
        font-family: 'Poppins', sans-serif;

        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .care {
        font-family: 'Poppins', sans-serif;
        font-size: 2.2rem;
        text-align: center;

    }

    .care2 {
        font-family: 'Poppins', sans-serif;

        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .in {
        width: 18rem;
        height: 2rem;
        margin-bottom: 2rem;
        display: block;
        margin: auto;
    }

    .in2 {
        width: 18rem;
        height: 7rem;
        margin-bottom: 2rem;
        display: block;
        margin: auto;
    }

    .btn {
        margin: auto;
        display: block;

        border: none;
        width: 11rem;
        height: 2.5rem;
        background-color: rgb(216, 0, 0);
        color: white;
        font-size: 1.1rem;
        font-weight: bold;
        border-radius: 0.3rem;
    }

    .face {
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        margin: auto;
        display: block;
    }

    .name {
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }

    .icon {
        width: 3rem;


    }

    .ways {
        text-align: center;
        font-size: 1.7rem;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    .otherHold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    .mapHold {
        width: 100%;
        margin: 5rem 0;
    }

    .locate {
        text-align: center;
        font-size: 2.2rem;
        font-weight: 600;
    }

}