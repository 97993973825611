.holdnew {
    margin-top: 6rem;
}

.cardNew {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/corporate.jpg");


    background-size: cover;
    object-fit: scale-down;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/

    border-radius: 1rem;
    display: flex;
}

.fatureHead {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
}

.fatureHead2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-top: -1rem;
}

.fatureBtnHold {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 76%;
    margin: auto;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
}


.fatureBtn {
    background-color: black;
    color: white;
    border: none;
    width: 14rem;
    height: 3rem;
    border-radius: 2.5rem;
    font-size: 1.1rem;
    transition: 0.5s;

}

fatureBtnHoldIn {
    margin-right: 1rem;
}

.quick {
    margin-top: 5%;

    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(222, 1, 1);
}

.Btn1 {
    background-color: white;
    color: black;
    margin-right: 1rem;
}

.Btn1:hover {
    background-color: rgb(222, 1, 1);
    color: white;
}

.SecurityCardHold {

    width: 100%;
    height: 31rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;


}

.SecurityHold2 {

    width: 100%;
    height: 18rem;
    background-size: cover;

}

.SecurityHold3 {

    width: 100%;
    height: 5rem;
    background-size: cover;

}


.txtNew {
    color: white;
    /*position: absolute;
    bottom: 0;*/
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    display: inline-block;
    align-self: flex-end;
    margin: auto;
    margin-top: 11rem;

}

@media screen and (max-width:768px) {


    .holdnew {
        width: 100%;
        text-align: center;
        align-items: center;
    }

    .fatureHead {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1.1rem;
    }


    .fatureHead2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: .9rem;
        margin-top: -1rem;
    }

    .quick {
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(222, 1, 1);
    }

    .fatureBtnHold {
        width: 98%;
    }

    .fatureBtn {
        width: 6.5rem;
        height: 3rem;
        border-radius: 2.5rem;
        font-size: 1rem;
        transition: 0.5s;

    }

    .Btn1 {
        font-size: .9rem;
        font-weight: 500;
    }

}