.namehold {
    margin-top: 6.4rem;
    background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-bottom: 3rem;
}

.name {
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;

}

.head {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.cardhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.card {
    background-image: linear-gradient(rgba(1, 1, 1, 0),
            rgba(0, 0, 0, 0.76)),
        url("../asset/apartment.webp");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card2 {
    background-image: linear-gradient(rgba(1, 1, 1, 0),
            rgba(15, 15, 15, 0.76)),
        url("../asset/school.jpg");

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card3 {
    background-image: linear-gradient(rgba(1, 1, 1, 0),
            rgba(15, 15, 15, 0.701)),
        url("../asset/hospital.webp");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card4 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/factory.webp");

    background-size: contain;
    background-repeat: no-repeat;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
}

.card5 {
    background-image: linear-gradient(rgba(1, 1, 1, 0),
            rgba(15, 15, 15, 0.621)),
        url("../asset/mall.webp");

    object-fit: fill;
    background-size: contain;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.card6 {
    background-image: linear-gradient(rgba(1, 1, 1, 0),
            rgba(15, 15, 15, 0.717)),
        url("../asset/residence.webp");

    object-fit: fill;
    background-size: contain;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.card7 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/hotel.jpg");

    object-fit: fill;
    background-size: contain;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.card8 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgb(15, 15, 15)),
        url("../asset/corporate.jpg");

    object-fit: fill;
    background-size: contain;
    height: 15rem;
    width: 27%;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inCard {
    width: 100%;
    display: inline-block;
    align-self: flex-end;
    text-align: center;
}


.txt {
    color: white;
    /*position: absolute;
    bottom: 0;*/
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .namehold {
        margin-top: 6.4rem;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        margin-bottom: 3rem;
    }

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: white;

    }

    .head {
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .cardhold {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .card {


        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card2 {

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card3 {


        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card4 {


        background-size: contain;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
    }

    .card5 {


        /*object-fit: fill;*/
        background-size: cover;

        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
        object-fit: fill;
    }

    .card6 {


        object-fit: fill;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
        object-fit: fill;
    }

    .card7 {


        /*object-fit: fill;*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
        object-fit: fill;
    }

    .card8 {


        object-fit: fill;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vh;
        width: 45vw;
        /*position: relative;*/
        display: flex;
        border-radius: 1rem;
        object-fit: fill;
    }

    .inCard {
        width: 100%;
        display: inline-block;
        align-self: flex-end;
        text-align: center;
    }


    .txt {
        color: white;
        /*position: absolute;
        bottom: 0;*/
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: bold;
    }
}