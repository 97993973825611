.cardHold {}

.card {
    background-color: white;
    padding-top: 0.7rem;
    padding-bottom: 1rem;
    width: 96%;
    color: black;
    border-radius: 0.2rem;
    font-family: 'Poppins', sans-serif;
    z-index: 1;
    box-shadow: 1px 2px 9px 2px red;
}

.quote {
    margin: auto;
    display: block;
    width: 5rem;
    margin-top: -1.7rem;
}

.txt {
    text-align: center;
}

.imgHold {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 2.5rem;
}

.name {
    text-align: end;
    margin-right: 1rem;
    font-size: 0.8rem;
}


.face {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
}

@media screen and (max-width:768px) {

    .card {
        padding-top: 0.7rem;
        padding-bottom: 1rem;
        width: 95%;
        color: black;
        border-radius: 0.2rem;
        font-family: 'Poppins', sans-serif;
        z-index: 1;
        box-shadow: 1px 2px 9px 2px red;
    }

    .imgHold {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin-right: 1.1rem;
    }

    .face {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
    }
}