.about {
    text-align: center;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    color: rgb(217, 0, 0);
}

.hold {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
}

.left {
    width: 35rem;
    height: 26rem;
}

.head2 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    color: rgb(62, 62, 62);

}

.head3 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    margin-top: -3rem;
    color: rgb(62, 62, 62);
}

.yrs {
    color: rgb(222, 0, 0);
}

.btn {
    background-color: rgb(214, 0, 0);
    border: none;
    border-radius: 0.3rem;
    margin-top: 1rem;
    width: 10rem;
    height: 3rem;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
}

.lnk {
    text-decoration: none;
    color: white;
}

.listHold {
    margin-top: -2rem;
    font-size: 1.1rem;
}

.list {
    margin-bottom: 0.5rem;
}

@media screen and (max-width:768px) {
    .html {
        font-size: 45%;
    }

    .hold {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
    }

    .left {
        width: 89%;
        margin: auto;
        display: block;
    }

    .right {
        margin-top: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        ;
        text-align: left;
    }

    .about {
        text-align: center;
        font-size: 1.1rem;
        margin-bottom: -1rem;
    }

    .head2 {
        font-family: 'Poppins', sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
        color: rgb(62, 62, 62);

    }

    .head3 {
        font-family: 'Poppins', sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
        margin-top: -2rem;
        color: rgb(62, 62, 62);
    }

    .list {

        margin-bottom: 0.5rem;
    }

    .btn {
        border-radius: 0.3rem;
        margin-top: 1rem;
        width: 9rem;
        height: 3rem;
        font-size: 1rem;
        font-weight: 500;
    }

    .listHold {
        margin: auto;
        display: block;
        font-size: .85rem;
        margin-left: 5px;
    }
}