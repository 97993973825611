.hold {
    font-family: 'Poppins', sans-serif;
}

.left {
    width: 50%;
    background-color: rgb(233, 255, 233);
}

.right {
    width: 50%;
}


.head {
    font-size: 2.2rem;
    font-weight: 500;
    margin-left: 2.3rem;
}

.point {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;

}

.txt {
    font-size: 1.1rem;
}

.lin {
    margin-bottom: 1.2rem;
}

.divHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4%;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .divHold {

        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1%;

    }

    .head {
        font-size: 1.1rem;
        font-weight: 500;
        margin-left: 1.3rem;
    }

    .point {
        width: .5rem;
        height: .5rem;
        margin-right: 0.4rem;

    }


    .left {
        width: 47%;
        background-color: rgb(233, 255, 233);
    }

    .right {
        width: 47%;
    }

    .txt {
        font-size: .7rem;
    }
}