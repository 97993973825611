 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto+Slab:wght@500&display=swap');
 /*font-family: 'Roboto Slab', serif;*/

 .hold {
     width: 100%;
     background-image: linear-gradient(rgb(0, 0, 0), rgb(108, 108, 108));
     margin-top: 5rem;
     padding-top: 1rem;
     padding-bottom: 0.5rem;
     color: white;
     font-family: 'Poppins', sans-serif;
 }

 ul {
     list-style-type: none;
 }

 li {
     margin-bottom: 0.5rem;
 }

 .hold1 {
     display: flex;
     flex-direction: row;
     justify-content: space-between;

 }

 .iconHold {
     display: flex;
     flex-direction: row;
     gap: 1rem;
     margin-left: 2rem;

 }

 .icon {
     width: 3rem;
 }

 .icon2 {
     width: 2rem;
     height: 2rem;
     margin-right: 1rem;
     margin-top: 0.5rem;
 }

 .btn {
     width: 9rem;
     height: 3rem;
     font-size: 1.1rem;
     font-weight: bold;
     margin-right: 2rem;
     color: white;
     background-color: rgb(205, 3, 3);
     border: none;
     border-radius: 0.4rem;
 }

 .btmHold {
     display: flex;
     flex-direction: row;
     justify-content: center;
     gap: 8rem;
 }



 .quickHold {
     margin-left: 3rem;
 }

 .quick {
     font-family: 'Roboto Slab', serif;
     color: white;
     font-size: 1.7rem;
     font-weight: bold;

 }

 .btm22 {
     display: flex;
     flex-direction: row;
     justify-content: center;
     gap: 2rem;
 }

 .num {
     color: white;
     font-size: 1.1rem;
 }

 .abtHold {
     display: flex;
     flex-direction: row;
     justify-content: center;
     gap: 2rem;
 }

 .copy {
     text-align: center;
     font-size: 0.85rem;
 }

 .hrt {
     width: 0.8rem;
     height: 0.8rem;
     margin-right: 0.2rem;
     margin-left: 0.5rem;

 }

 .linker2 {
     text-decoration: none;
     color: white;


 }

 .iso {
     margin-left: 0.4rem;
 }

 .address {
     margin-left: 0.4rem;
     width: 16rem;
 }

 .address2 {
     font-weight: bold;
     font-size: 1.1rem;
 }

 @media screen and (max-width:768px) {
     .hold {
         width: 100%;
         margin-top: 5rem;
         padding-top: 1rem;
         padding-bottom: 0.5rem;
         color: white;
         font-family: 'Poppins', sans-serif;
     }

     ul {
         list-style-type: none;
     }

     li {
         margin-bottom: 0.5rem;
     }

     .hold1 {
         display: flex;
         flex-direction: row;
         justify-content: space-between;

     }

     .iconHold {
         display: flex;
         flex-direction: row;
         gap: 1rem;
         margin-left: 2rem;

     }

     .icon {
         width: 3rem;
     }

     .icon2 {
         width: 2rem;
         height: 2rem;
         margin-right: 1rem;
         margin-top: 0.5rem;
     }

     .btn {
         width: 9rem;
         height: 3rem;
         font-size: 1.1rem;
         font-weight: bold;
         margin-right: 2rem;
         color: white;
         background-color: rgb(205, 3, 3);
         border: none;
         border-radius: 0.4rem;
     }

     .btmHold {
         display: flex;
         flex-direction: column;
         justify-content: center;
         gap: 3rem;
     }

     .btm1 {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
     }

     .btm2 {
         display: flex;
         flex-direction: column;
         justify-content: center;
     }

     .logo {
         width: 11rem;
     }

     .quickHold {
         display: flex;
         justify-content: center;
     }

     .quick {
         font-family: 'Roboto Slab', serif;
         color: white;
         font-size: 1.7rem;
         font-weight: bold;
         margin-right: 2rem;

     }

     .btm22 {
         display: flex;
         flex-direction: row;
         justify-content: center;
         gap: 2rem;
     }

     .num {
         color: white;
         font-size: 1.1rem;
     }

     .abtHold {
         display: flex;
         flex-direction: row;
         justify-content: center;
         gap: 2rem;
     }

     .copy {
         text-align: center;
         font-size: 0.85rem;
     }

     .hrt {
         width: 0.8rem;
         height: 0.8rem;
         margin-right: 0.2rem;
         margin-left: 0.5rem;

     }

     .linker2 {
         text-decoration: none;
         color: white;


     }

     .iso {
         margin-left: 0.4rem;
     }

     .address {
         text-align: center;
         width: 16rem;
     }

     .address2 {
         font-weight: bold;
         font-size: 1.1rem;
     }

     .contactsHold {
         display: flex;
         justify-content: center;
     }
 }