.hold {
    font-family: 'Poppins', sans-serif;
    margin-top: 5rem;
    background-color: black;
    padding: 2rem 0;
    color: white;
}

.hold1 {
    margin: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 2rem;
}

.img1 {
    border-radius: 1rem;
    width: 35rem;
    height: 32rem;
    object-fit: cover;
    background-position: center;

    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0);
    /* IE 9 */
    -webkit-transform: translateZ(0);
    /* Chrome, Safari, Opera */
    transform: translateZ(0);

}

.imgHouse {
    border-radius: 1rem;
    width: 30rem;
    height: 29rem;
    object-fit: cover;

}

.txt {
    margin-top: 2rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.txt2 {
    font-size: 1.3rem;

}

.btn {
    margin: auto;
    display: block;
    width: 29%;
    height: 13%;
    font-size: 1.2rem;
    background-color: rgb(222, 1, 1);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 0.5rem;
}

.btn:hover {
    background-image: linear-gradient(to right, red, green);
    transition: 2s;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .hold1 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    .img1 {
        width: 90vw;
        height: 40vh;
    }

    .imgHouse {
        border-radius: 1rem;
        width: 90vw;
        height: 40vh;
        object-fit: cover;

    }

    .txt {
        margin-top: -1rem;
        font-size: .9rem;
        margin-bottom: 2rem;
    }

    .btn {
        width: 40vw;
        height: 9vh;
    }
}