.hold {
    margin-top: 18vh;
    overflow: hidden;
}

@media screen and (max-width:768px) {


    .hold {
        width: 100%;
        margin-top: 8vh;
        overflow: hidden;
    }
}