.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
}

.pop {
    background-color: white;
    padding: 1rem;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .3);
    border: 0;
}

.close {
    background: red;
    border: 0;
    padding: .5rem 1rem;
    cursor: pointer;
    color: white;

}

.pop video {
    width: 40rem;
    margin-bottom: 1rem;
}

@media screen and (max-width:768px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .3);
    }

    .pop {
        background-color: white;
        padding: 1rem;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .3);
        border: 0;
        width: 88%;
    }

    .close {
        background: red;
        border: 0;
        padding: .5rem 1rem;
        cursor: pointer;
        color: white;

    }

    .pop video {
        width: 90%;
        margin-bottom: 1rem;
    }
}