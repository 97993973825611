.WhyCard {
    padding-top: 3rem;
    padding-bottom: 0.5rem;
    background-color: white;
    width: 41%;
}

.WhyCard:hover {

    background-color: rgb(252, 255, 88);
}

.head {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
}

.txt {
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: gray;
    margin-bottom: 4rem;
}

.icon {
    display: block;
    margin: auto;
    width: 5rem;
}

@media screen and (max-width:768px) {


    .WhyCard {
        width: 6.5rem;
    }

    .head {
        text-align: center;
        font-size: .9rem;
        font-weight: 500;
    }

    .txt {
        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        color: gray;
        margin-bottom: 4rem;
        font-size: 0.7rem;
    }

    .icon {
        display: block;
        margin: auto;
        width: 1.5rem;
    }
}