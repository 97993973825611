.hold {
    background-color: black;
    color: white;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.linehold {
    display: flex;
    flex-direction: row;

}

.line {
    background: red;
    border: none;
    height: 2px;
    width: 7rem;
    margin-top: 2rem;
    margin-left: 1rem;
}

.head1 {
    color: rgb(255, 0, 0);
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 1rem;
}

.head2 {
    margin-left: 1rem;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
}

.head2Hold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 13rem;
}

.head2Btn {
    margin-top: 4rem;
    width: 16rem;
    height: 4.5rem;
    border: none;
    background-color: rgb(223, 0, 0);
    border-radius: 1rem;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.lnk {
    text-decoration: none;
    color: white;
    font-weight: 600;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .head2Hold {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
    }

    .head2Btn {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 5px;
        width: 12rem;
        height: 4.5rem;
        border: none;
        background-color: rgb(223, 0, 0);
        border-radius: 1rem;
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .head2 {
        margin-left: 1rem;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: bold;
    }
}