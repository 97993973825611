.contain {
    margin-top: 6.4rem;
}

.hold {
    text-align: center;
    color: white;
    background-color: black;
    margin: 0 6rem;
    border-radius: 1rem;
}

.join {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
}

.join2 {
    margin-top: -3rem;
    margin-bottom: 4rem;
    text-align: center;
    font-size: 1.2rem;
    color: gray;

}

.hold2 {

    padding: 1rem 0;
    margin: auto;
    display: block;
    border: 2px solid;
    border-image:
        linear-gradient(to bottom,
            red,
            rgba(114, 234, 9, 0)) 1 100%;
    width: 70%;
}

.in {
    width: 70%;
    height: 8vh;
    margin-bottom: 6vh;
}

.btn {
    width: 21%;
    height: 7vh;
    border: none;
    border-radius: 0.2rem;
}

.btn2 {
    margin-left: 2rem;
    margin-bottom: 6vh;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
        margin: 0;
        text-align: center;
        color: white;
        background-color: black;

        border-radius: 1rem;
    }

    .contain {
        margin-top: 6.4rem;
        width: 100%;
    }

    .join {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
    }

    .join2 {
        margin-top: -1rem;
        margin-bottom: 4rem;
        text-align: center;
        font-size: 1.1rem;
        color: gray;

    }

    .hold2 {

        padding: 1rem 0;

        border: 2px solid;
        border-image:
            linear-gradient(to bottom,
                red,
                rgba(114, 234, 9, 0)) 1 100%;
        width: 70%;
    }

    .in {
        width: 80%;
        height: 7vh;
        margin-bottom: 6vh;
    }

    .btn {
        width: 50vw;
        height: 7vh;
        border: none;
        border-radius: 0.2rem;
    }

    .btn2 {
        margin-left: 2rem;
        margin-bottom: 6vh;
    }

}