.hold {
    background-color: rgb(0, 106, 255);
    padding: 0.5rem 0;
    margin-bottom: 5rem;
    margin-top: 4rem;
}

.head {
    margin-top: -0.5rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: white;
}

.head2 {
    text-align: center;
    font-size: 1.1rem;

    color: white;
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.formHold {
    display: flex;
    justify-content: center;
}

.in {
    width: 17rem;
    height: 3rem;
    margin-right: 1rem;
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.btn {

    margin: auto;
    display: block;
    width: 15rem;
    height: 3rem;
    border: none;
    border-radius: 0.5rem;
    background-color: black;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

.btn:hover {
    background-color: rgb(58, 58, 58);

}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .in {
        width: 70%;
        height: 3rem;
        margin: 3% 13%;

        border: none;
        border-radius: 0.5rem;
        text-align: center;
    }

    .btn {
        margin-top: 2rem;
        width: 11rem;
        height: 3rem;
        border-radius: 0.5rem;
        font-size: 1.2rem;
        font-weight: 600;
    }
}