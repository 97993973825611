.hold {
    font-family: 'Poppins', sans-serif;
    margin-top: 3rem;
    background-image: linear-gradient(rgb(255, 255, 255),
            rgba(255, 255, 255, 0.676)),
        url("./asset/office.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 1rem 0;

}

.head {
    font-size: 2.1rem;
    font-weight: 600;
    text-align: center;
}

.head2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

.txt {
    margin: 0 2rem;
    margin-bottom: 0.5rem;
}

.chart {
    margin-top: 4rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.chartColor {
    color: rgb(225, 0, 0);
}

.pdf {
    margin: auto;
    display: block;
}

@media screen and (max-width:768px) {
    .hold {
        font-family: 'Poppins', sans-serif;
        margin-top: 3rem;
        background-image: linear-gradient(rgb(255, 255, 255),
                rgba(255, 255, 255, 0.676)),
            url("./asset/office.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        padding: 1rem 0;
        width: 100%;
    }

    .head {
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
    }

    .head2 {
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
    }

    .txt {
        margin: 0 2rem;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
    }

    .chart {
        margin-top: 4rem;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
    }

    .chartColor {
        color: rgb(225, 0, 0);
    }

    .pdf {
        margin: auto;
        display: block;
    }
}