.divsHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    font-family: 'Poppins', sans-serif;
}

.namehold {
    margin-top: 6.4rem;
    background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.name {
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;

}

.lft {
    position: sticky;
    width: 50%;
    height: auto;
    background-image: linear-gradient(rgba(232, 232, 232, 0.454), rgb(236, 236, 236));
}

.card {
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    box-shadow: 1px 1px 8px 2px rgb(207, 207, 207);
}

.head {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(43, 43, 43);
}

.txt {
    text-align: center;
    color: rgb(80, 80, 80);
    margin-left: 1rem;
    margin-right: 1rem;
}



.right {
    width: 50%;
}

.iconfacility {
    width: 35rem;
    margin: auto;
    display: block;
    margin-top: 1rem;
}

.iconHouseT {
    margin-left: 1.5%;
    margin-top: 2rem;
    width: 37rem;

}

.iconSecurity {
    width: 38rem;
    margin-top: 5rem;
}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
    }

    .divsHold {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        font-family: 'Poppins', sans-serif;
    }

    .namehold {
        margin-top: 6.4rem;
        background-image: linear-gradient(to bottom right, red, rgb(0, 0, 0));
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        width: 100%;
    }

    .name {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: white;

    }

    .lft {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: sticky;
        width: 50%;
        height: auto;
        background-image: linear-gradient(rgba(232, 232, 232, 0.454), rgb(236, 236, 236));
    }

    .card {
        margin-left: 15px;
        width: 98vw;
        margin-top: 1rem;
        margin-right: 1rem;
        border: 1px solid rgb(194, 194, 194);
        border-radius: 0.5rem;
        margin-bottom: 2rem;
        box-shadow: 1px 1px 8px 2px rgb(207, 207, 207);
    }

    .head {
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: rgb(43, 43, 43);
    }

    .txt {
        text-align: center;
        color: rgb(80, 80, 80);
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .iconfacility {
        width: 95vw;
        height: 48vh;
        margin: auto;
        border-radius: 5px;
        display: block;
        margin-top: 1rem;
    }

    .iconHouseT {
        margin-left: 1.5%;
        margin-top: 2rem;
        width: 95vw;
        height: 35vh;
        border-radius: 5px;

    }

    .iconSecurity {
        width: 95vw;
        height: 35vh;
        border-radius: 5px;
        margin-top: 2rem;
    }


    .divsHold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        font-family: 'Poppins', sans-serif;
    }

    .lft {
        width: 100%;
        background-image: linear-gradient(rgba(242, 242, 242, 0.454), rgb(255, 255, 255));
    }

    .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .head {
        font-size: 1.4rem;
    }

    .txt {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem;
    }
}