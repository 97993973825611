.hold {
    width: 100%;
    padding-top: 2rem;
    height: 21rem;
    background-image: linear-gradient(rgba(252, 252, 252, 0.97),
            rgba(255, 255, 255, 0.897)),
        url("./asset/microsoft.avif");
    background-repeat: no-repeat;
    background-size: 100%;
    object-fit: cover;
}

.head1 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
    color: rgb(62, 62, 62);
}

.head2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(221, 0, 0);
    margin-top: -2rem;
}

.head3Hold {
    display: flex;
    justify-content: center;
}

.head3 {
    text-align: center;
    width: 70%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    color: rgb(99, 99, 99);

}

@media screen and (max-width:768px) {

    .hold {
        width: 100%;
        padding-top: 2rem;
        height: 21rem;
        background-image: linear-gradient(rgba(252, 252, 252, 0.97),
                rgba(255, 255, 255, 0.897)),
            url("./asset/microsoft.avif");
        background-repeat: no-repeat;
        background-size: 100%;
        object-fit: cover;
    }

    .head1 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 2.2rem;
        font-weight: 600;
        color: rgb(62, 62, 62);
    }

    .head2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: rgb(221, 0, 0);
        margin-top: -2rem;
    }

    .head3Hold {
        display: flex;
        justify-content: center;
    }

    .head3 {
        text-align: center;
        width: 70%;
        font-family: 'Poppins', sans-serif;
        font-size: 1.1rem;
        color: rgb(99, 99, 99);

    }
}