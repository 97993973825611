.card {
    border: 1px solid rgb(222, 222, 222);
    font-family: 'Poppins', sans-serif;
    width: 25%;
    padding-top: 0.5rem;
    border-radius: 0.4rem;

}

.icon {
    width: 4rem;
    height: 4rem;
    margin: auto;
    display: block;
}



.head {
    color: rgb(232, 0, 0);
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
}

.txt {
    text-align: center;
    color: rgb(65, 65, 65);
}

@media screen and (max-width:768px) {
    .card {
        width: 45%;
    }

    .txt {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: .9rem;
    }
}