.container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
}

.box {
    padding: 1rem;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    column-gap: -2rem;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 25rem;
}

.box img {
    width: 5rem;
    box-shadow: 1px 1px 1px 1px rgb(0, 0, 0, .2);
    padding: 1rem;

}

.imgvision {
    margin-top: -6rem;
}

.imgvalues {
    margin-top: -3rem;
}

.box p {
    width: 20rem;
    font-size: .8rem;
}

.p1 {
    margin-top: -.5rem;
}

@media screen and (max-width:768px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;
    }

    .box {
        padding: 1rem;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .2);
        display: flex;
        flex-direction: column;
        column-gap: -2rem;
        justify-content: center;
        align-items: center;
        width: 20rem;
        height: 25rem;
    }

    .box img {
        width: 5rem;
        box-shadow: 1px 1px 1px 1px rgb(0, 0, 0, .2);
        padding: 1rem;

    }

    .imgvision {
        margin-top: -6rem;
    }

    .imgvalues {
        margin-top: -3rem;
    }

    .box p {
        width: 20rem;
        font-size: .8rem;
    }

    .p1 {
        margin-top: -.5rem;
    }
}