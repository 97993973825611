@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


.navbar {
    width: 100%;
    height: 6.1rem;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;

}

.act2 {
    opacity: .65;
    font-size: .5rem;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1500px;
}

.nav-logo {
    cursor: pointer;
}

.nav-logo img {
    width: 7rem;
    height: 5.6rem;
    margin-left: 2rem;
    margin-top: 6px;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    list-style: none;
    text-align: center;
    margin-right: 3rem;
}

.nav-links {
    color: #153C5B;
    text-align: left;
    font-size: 1.2rem;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.26px;
    opacity: .75;
    position: relative;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
    margin-top: .5rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: rgb(239, 0, 0);
}

.nav-item .active {
    color: #153C5B;
    opacity: 1;
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.26px;

}

.nav-icon {
    display: none;
}




/* Dropdown Button */
.dropbtn {
    color: #153C5B;
    font-size: 1rem;
    margin-top: -.2rem;
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: .75;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 13rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;

    border-radius: 0.3rem;

    padding-top: 0;
}

/* Links inside the dropdown */

.plus {
    width: 0.8rem;
    margin-left: 0.1rem;
}

.linker {
    text-decoration: none;
}

a {
    color: black;
}

.dropdown-content a {
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
    border-top: 1px solid orangered;
    padding-left: 1rem;
}

.dropdown-content .aref1 {
    border: none;
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
    padding-left: 1rem;
}

/* Change color of dropdown links on hover */
.dropdown-content .aref:hover {
    background-image: linear-gradient(to right, #ba0c01, #ed5c01);
    color: white;
}

.dropdown-content .aref1:hover {
    background-image: linear-gradient(to right, #ba0c01, #ed5c01);
    color: white;
}

.dropdown-content a:hover {
    background-color: black;
    color: #fff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */



@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;

        border-top: 1pxsolid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .act2 {
        opacity: .65;
        font-size: 1.2rem;
        font-weight: 400;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;

    }


    .nav-menu.active {
        background: #153C5B;
        left: 0px;

        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        width: 89%;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .nav-item {
        text-align: left;
        font-family: 'Roboto', sans-serif;
    }

    .nav-item .active {
        color: white;
        border: none;
        font-weight: bold;
    }

    .nav-links {
        padding: 0rem;
        width: 100%;
        display: table;
        color: white;

    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #153C5B;

    }

    .dropbtn {
        color: white;
        font-size: 1rem;

        background: transparent;
        border: 0;
        cursor: pointer;
        opacity: .75;
        transform: translateX(-8.4rem);

    }

    .nav-item .active {
        color: white;
        opacity: 1;
        font-weight: 500;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0.26px;

    }

    .nav-logo1 img {
        width: 10rem;
        margin-left: 0rem;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        /*background-color: #274b67;*/
        background-color: #fff;
        min-width: 16rem;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 2;
        transform: translateY(.3rem);
        border-radius: 1rem;
        color: white;
        overflow-x: hidden;

        padding-top: 0;
    }

    .dropdown-content .aref {
        color: white;
        text-align: left;
        text-decoration: none;
        display: block;
        border-top: 1px solid orangered;
        padding-left: 1rem;
    }

    .dropdown-content .aref1 {
        border: none;
        color: white;
        text-align: left;
        text-decoration: none;
        display: block;
        padding-left: 1rem;
    }


}