.leftImg {
    width: 30rem;
    height: 27rem;
}

.inDiv1 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/hk2.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 124%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv2 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/meet.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
    height: 25rem;
    width: 35rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv3 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/qrt3.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv4 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/sec1.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv5 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/dog1.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv6 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/HomePolice.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

.inDiv7 {
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(151, 151, 151, 0.155)),
        url("../asset/new/lift.jpg");

    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 25rem;
    width: 30rem;
    /*position: relative;*/
    display: flex;
    border-radius: 1rem;
    object-fit: fill;
}

@media screen and (max-width:768px) {
    .hold {
        width: 100%;
    }

    .leftImg {
        width: 100%;

    }

    .inDiv1 {
        height: 21rem;
        width: 95%;
    }

    .inDiv2 {
        height: 21rem;
        width: 95%;
    }

    .inDiv3 {
        height: 21rem;
        width: 95%;
    }

    .inDiv4 {
        height: 21rem;
        width: 95%;
    }

    .inDiv5 {
        height: 21rem;
        width: 95%;
    }

    .inDiv6 {
        height: 21rem;
        width: 95%;
    }

    .inDiv7 {
        height: 21rem;
        width: 95%;
    }
}